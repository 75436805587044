const appConfig = {
    apiUrl: "https://api.haasiltech.com/api/api/",
    // apiUrl: "http://192.168.0.234:4040/api",
    // apiUrl: "http://192.168.0.181:4040/api",

    encryptionKey: "sUOw9bXOO4JUFFu05vWoDtDd8O0cl0Anz"
}
// asndkjasnk
export { appConfig }

