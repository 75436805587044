import { useNavigate } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { Button, Col, Divider, Image, Row, Select, Spin } from "antd";
import Statusboxes from "../../Components/Boxes/Statusboxes";
import { Images } from "../../Assets/Images/Images";
import { Colors } from "../../Assets/Colors";
import ProductReccomendation from "../../Components/Tables/Productrecomendation";
import ItemAndDescription from "../../Components/Tables/Item.discription";
import { BarChartOutlined, ClockCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { getAllDashboardCount, getProductRecomdentaionProduct, getRecurringProduct } from "../../API-Interaction/api-functions/dashboard.api";
import { useDispatch, useSelector } from "react-redux";
import BarChartDashboard from "../../Components/Charts/DashboardBarChart";
import moment from "moment/moment";
import NewChart from "../../Components/Charts/NewGraph";
import { BarChartData } from "../../Components/helper/BarChartData";
import { onAddToCart } from "../../API-Interaction/api-functions/cart.api";

const DashboardMain = () => {
  const [DashboardData, setDashboardData] = useState('')
  const navigate = useNavigate()
  const { pageLoader } = useSelector(state => state.utils.loaders)
  const [Time, setTime] = useState("")
  const [productRecomendationData, setProductRecomendationData] = useState([])
  const [recurringProducts, setrecurringProducts] = useState([])
  console.log("🚀 ~ DashboardMain ~ recurringProducts:", recurringProducts)
  const dispatch = useDispatch()
  const token = localStorage.getItem('authTokenHaasil')
  const cart = useSelector((state) => state?.cart?.cartItems);



  const callDashboardApiForCount = async () => {
    const response = await getAllDashboardCount(dispatch, token)
    if (response?.success) {
      setDashboardData(response?.response)
    }
  }

  const callProductRecomendationProducts = async () => {
    const result = await getProductRecomdentaionProduct(dispatch, token)
    if (result.success)
      setProductRecomendationData(result?.response)
  }
  const callRecurringProduct = async () => {
    const result = await getRecurringProduct(dispatch, token)
    console.log("🚀 ~ callRecurringProduct ~ result:", result)
    if (result?.success) {
      setrecurringProducts(result?.totalProducts)
    }
  }
  const selectHandleChange = (value) => {
  };

  const handleCreateOrder = () => {
    if (recurringProducts.length !== 0) {

      recurringProducts.forEach((item, index) => {
        onAddToCart(item?.Product, Math.floor(item?.ProductCount), dispatch, cart);
        console.log(item?.Product?.name)

      })
    }

    // alert('hello world')
  }

  useEffect(() => {
    callDashboardApiForCount()
    callProductRecomendationProducts()
    callRecurringProduct()
    // const  = new Date()
    setTime(moment().format('LT'))
  }, [])

  return (
    <Layout>
      <Spin spinning={pageLoader?.getAllDashboardCount || false}>
        <div className="">

          <Row className="flex justify-between items-center w-full mb-3  rounded-lg bg-[#f7f7f7]" >
            <Col md={10} xs={24}>
              <div className=" w-full  ">
                <div className="flex justify-center items-center h-[200px]  w-full " style={{
                }} >
                  {/* <video controls autoplay={true} className="w-full">
                    <source src={Images.HaasilVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> */}
                  <img src={Images.banner2} className="object-fit  h-full" />
                </div>
              </div>
            </Col>
            <Col md={14} xs={24} className="w-full h-full">
              <Row className="flex justify-end  status-box-cont h-full">
                {
                  [{ name: 'Draft', number: DashboardData?.draft ? DashboardData?.draft : "0", image: Images.Draft, color: "#f15793", info: 'Order' },
                  { name: 'In Progress', number: DashboardData?.inProgress ? DashboardData?.inProgress : "0", image: Images.Check, color: "orange ", info: 'Orders' },
                  { name: 'Completed', number: DashboardData?.completed ? DashboardData?.completed : "0", image: Images.Tick, color: "#13b886", info: 'Orders' },
                    // { name: 'Completed', number: DashboardData?.completed ? DashboardData?.completed : "0", image: Images.Tick, color: "#8aa0c2" },


                  ].map((item, index) => {
                    return (
                      <Col lg={8} xs={24} className="h-full" >
                        <Statusboxes Name={item?.name} Number={item?.number} Image={item?.image} color={item?.color} info={item?.info} />
                      </Col>
                    )
                  })}
              </Row>

            </Col>
          </Row>


          <Row className="w-full relative">
            {/* Left Side Bar */}
            <Col lg={12} md={24} className="overflow-hidden flex flex-col gap-1  " >
              <div className=" rounded-lg  overflow-hidden">
                <div>
                  <p className="font-black text-[20px] mb-[10px] ">Switch & Save</p>
                </div>

                <div className="p-5 py-7 bg-[#f7f7f7] rounded-[25px]" >
                  <ProductReccomendation productData={productRecomendationData} />
                </div>
              </div>


              <div className="py-4  h-[400px]  rounded-lg">
                <p className="font-black text-[20px] pl-4 ">Purchases by Month and Product Categories</p>

                <div className="w-full h-[400px] flex items-start justify-center  rounded-[10px]">
                  <BarChartDashboard />
                  <div className=" px-2 h-full ">
                    <div className="h-[96%] flex flex-col overflow-y-scroll gap-4 BarChartGraphData">
                      {BarChartData.map((item, index) => {
                        return (<div className="flex gap-1">
                          <BarChartOutlined className="text-[20px]" style={{ color: item?.color, }} />
                          <p className=" text-[gray]">{item?.name}</p>
                        </div>)
                      })}

                    </div>
                  </div>
                  {/* <NewChart /> */}
                </div>
              </div>


            </Col >
            {/* Right Side Bar */}

            <Col lg={12} md={24} xs={24}>
              <Row className="flex  flex-col w-full    overflow-hidden" >
                <div className="rounded-lg pl-5 w-full overflow-hidden ">
                  <div className="flex justify-between items-center ">
                    <p className="font-black text-[20px] mb-[10px]">Recurring Order</p>
                    <Button className={`text-[${Colors.darkBlue}]   text-[14px] font-semibold bg-[#f1f5fd] mr-3 rounded-lg mb-2 `} onClick={() => { handleCreateOrder() }}>Create Order</Button>
                  </div>
                  <div className="p-7  bg-[#f7f7f7] rounded-[25px]" >
                    <ItemAndDescription recurringProducts={recurringProducts} />
                  </div>
                </div>
                <Row className="w-full mb-4 pl-5">
                  <Col span={24} className="pr-1 mt-5">
                    <div className={` h-[400px] rounded-lg w-full   flex flex-col   `}>
                      <div className="flex  justify-between">
                        <div className="flex flex-col gap-3 ">
                          <p className="flex  font-bold text-[18px]">Total Products Ordered :{DashboardData?.productCount}</p>
                          {/* <p className="large-font" style={{ fontSize: "25px" }}>Count:{DashboardData?.productCount}</p> */}
                        </div>
                        {/* <div>
                        <Select
                          className="rounded-[10px] text-[#061E3A]"
                          defaultValue="YTD"
                          style={{
                            border: `2px solid ${Colors.darkBlue}`,
                            width: 100

                          }}
                          onChange={selectHandleChange}
                          options={[

                            {
                              value: 'YTD',
                              label: 'YTD',
                            },
                            {
                              value: 'MON',
                              label: 'MON',
                            },
                            {
                              value: 'Week',
                              label: 'Week',
                            },
                          ]}
                        />
                      </div> */}
                      </div>
                      <Divider className="my-[10px] " style={{
                        background: '#f7f7f7'
                      }} />

                      {/* <p className="text-[gray] mb-[10px]" style={{ fontSize: "18px" }}>Your Top Products:</p> */}
                      <div className="overflow-y-scroll flex flex-col gap-3 DashboardContainer">
                        {
                          DashboardData?.totalProducts?.length !== 0 &&
                          DashboardData?.totalProducts?.map((item, index) => {
                            return (
                              <div className="flex justify-between">
                                <div className="flex  items-center w-full gap-2">
                                  <div className="p-[1px] overflow-hidden rounded-[15px] h-[60px] w-[60px] flex justify-center items-center" style={{
                                    // border: "1px solid black",
                                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                                  }}>
                                    <Image
                                      className="h-[30px] w-[30px] object-contain"
                                      src={item?.Product?.imageURLs ? item?.Product?.imageURLs[0] : Images.HasilInvoiceLogo}
                                    // src={Images?.CameraUpload}
                                    />
                                  </div>
                                  <div className="flex flex-col " >

                                    <p className="font-bold whitespace-nowrap overflow-hidden w-[250px] text-ellipsis" style={{ fontSize: "12px" }}>
                                      {item?.Product?.name}
                                    </p>

                                    <p style={{
                                      fontSize: "9px", whiteSpace: 'nowrap',
                                      width: '100%',
                                      overflow: 'hidden',
                                      textOverflow: "ellipsis",
                                      color: "gray"
                                    }}><span style={{ fontWeight: "700" }}>Variant : </span> {item?.Product?.productCategoryL8?.name ? item?.Product?.productCategoryL8
                                      ?.name : "N/A"}</p>
                                    <p className="p-0" style={{
                                      fontSize: "9px", whiteSpace: 'nowrap',
                                      width: 'fit-content',
                                      overflow: 'hidden',
                                      textOverflow: "ellipsis",
                                      color: "gray",
                                      fontWeight: "700",
                                      textAlign: 'center',
                                      borderRadius: "10px",
                                      color: Colors.Lightblue

                                    }}>{item?.Product?.productCategoryL5?.name ? item?.Product?.productCategoryL5?.name : "Manufacturer : N-A"}</p></div>

                                </div>
                                <p className="font-black">{item.ProductCount}</p>
                              </div>
                            )
                          })
                        }
                      </div>

                    </div>
                  </Col>
                  {/* <Col lg={11} xs={0} className="pl-1 mt-5 ">
                  <div className={`bg-[#F7F7F7] rounded-[20px] w-full  flex flex-col gap-5 items-center justify-centerHo h-[450px]`}>
                    <h1 className="font-[900] text-[40px] text-center ">
                      Daily Essentials
                    </h1>
                    <img src={Images.Mockup} className="object-contain" />
                    <Button onClick={() => { navigate('/daily-essentials') }} className={`bg-[white] border-1 border-[${Colors.Lightblue}] text-[${Colors.Lightblue}] shadow-[1px 3px 4px rgba(0, 0, 0, 0.25)] font-bold`}
                      style={{
                        boxShadow: '1px 3px 4px rgba(0, 0, 0, 0.25)',
                        border: `2px solid ${Colors.Lightblue}`
                      }}>
                      Get Started</Button>
                  </div>
                </Col> */}

                </Row>

              </Row>


            </Col >

          </Row>
        </div>

      </Spin>

    </Layout >

  );
};

export default DashboardMain;
